export const RP_TESTIMONIAL = [
    {
      image: 'https://res.cloudinary.com/quicksense/image/upload/v1564115869/Culture_FLow_Logo_belzwq.png',
      name: 'Cultureflow',
      author: 'Sarah Reo | CEO',
      testimonial: '“Great service and a simple to use platform. Vivek and the team at Learning Curve have made it easy for me to help train people in Te Reo Māori across New Zealand”',
    },
    {
      image: 'https://res.cloudinary.com/quicksense/image/upload/v1566199048/Aro_Digital_Logo_f2k2wx.png',
      name: 'Aro Digital',
      author: 'Tim Dorrian | Managing Director',
      testimonial: '“The team at Learning Curve have done an outstanding job! They’ve turned our Digital Marketing content into interesting stories that people like learning from.”'
    }
]