export const HOW_IT_WORKS_DATA = [
    {
        image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,h_240,q_auto/v1625045915/Screen_Shot_2021-06-30_at_9.38.19_PM_ovwf5e.png',
        imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,h_240,q_auto/v1625045915/Screen_Shot_2021-06-30_at_9.38.19_PM_ovwf5e.webp',
        imageCaption: 'Rush Digital Thumbnail',
        imageClassName: 'fit-contain',
        background: '#161920',
        header: 'Case Study',
        title: 'Rush Digital',
        description: 'Teaching intellectual property in a practical way to salespeople and software developers',
        buttonText: 'Read the case study',
        buttonType: 'internal',
        buttonAction: '/cases/rush-digital',
        shadow: true,
        cardBackground: 'bg-white'
    },
    {
        image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,h_240,q_auto/v1625043584/Cultureflow_Case_Study_Cover_jkyoau.png',
        imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,h_240,q_auto/v1625043584/Cultureflow_Case_Study_Cover_jkyoau.webp',
        imageCaption: 'Cultureflow Thumbnail',
        imageClassName: 'fit-cover',
        header: 'Case Study',
        title: 'Cultureflow',
        description: 'Teaching te reo Māori (the indigenous language of New Zealand)',
        buttonText: 'Read the case study',
        buttonType: 'internal',
        buttonAction: '/cases/cultureflow',
        background: '#d4cac9',
        shadow: true,
        cardBackground: 'bg-white'
    },
    {
        image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,h_240,q_auto/v1625045067/Engage_Case_Study_Thumbnail_gt91wt.png',
        imageWebp: 'https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,h_240,q_auto/v1625045067/Engage_Case_Study_Thumbnail_gt91wt.webp',
        imageCaption: 'Engage Case Study Thumbnail',
        imageClassName: 'fit-contain',
        background: '#5b499d',
        header: 'Case Study',
        title: 'Engage',
        description: 'Creating New Zealand\'s first resource (and qualification) on how to engage with government',
        buttonText: 'Read the case study',
        buttonType: 'internal',
        buttonAction: '/cases/engage',
        shadow: true,
        cardBackground: 'bg-white'
    }
]
