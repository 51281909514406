import React from 'react'
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import SEO from '../components/SEO/seo'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'
import GetStarted from '../components/GetStarted/getStarted'
import HowItWorksItem from '../components/HowItWorksItem/howItWorksItem'
import Testimonials from '../components/Testimonials/testimonials'

//utils
import { HOW_IT_WORKS_DATA } from '../utils/content/howItWorksData'
import { WHY_LEARNING_CURVE } from '../utils/content/whyLearningCurve'
import { RP_TESTIMONIAL } from '../utils/content/rpTestimonials'
import { CUSTOMER_LOGOS } from '../utils/content/rpCustomerLogos'


class IndexPage extends React.Component {
    __renderHeroSection() { 
        return <div>
            <div className={'d-flex align-items-center heroBg'} data-sal="fade" data-sal-duration="800" data-sal-delay="1200" data-sal-easing="ease-out-quad">
                <Container className={'heroContainer'}>
                    <Row className='d-flex justify-content-center'>
                        <Col className={'heroSection col-md-12 col-lg-10 text-center'} as='section' md={12} lg={10}>
                            <h1 data-sal='slide-up' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart" className={'heroTitle'}>We are an agency that creates online courses for you</h1>
                            <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay="800" data-sal-easing="ease-out-quart" className={'heroDescription'}>Our team of learning experts will research, interview, compile and develop all of the content and put it on the platform of your choice —  a complete product ready for you to use for your website, organisation or audience. </p>
                            <div className=''data-sal='slide-up' data-sal-duration='2000' data-sal-delay="800" data-sal-easing="ease-out-quart">
                                {/* WRAPPER FOR ANIMATION */}
                                <Link className='btn btn-primary px-5' to='/contact'>Talk with us</Link>
                                {/* MOBILE BUTTON*/}
                                <Link to={'/cases'} className='btn btn-link --underline d-block d-sm-none'>Read our case studies</Link>
                                {/* DESKTOP BUTTON*/}
                                <Link to={'/cases'} className='btn btn-outline-primary d-none d-sm-inline-block ml-3'>Read our case studies</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    __renderMonitizeKnowledge () {
        return <Section background='bg-salmon'>

            <Col lg={6} className='col-lg-6 d-flex flex-column justify-content-center'>
                <h2 className='h1' data-sal='slide-up' data-sal-duration='2000' data-sal-delay="500" data-sal-easing="ease-out-quart">Monetise your knowledge into a course. All done for you.</h2>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay="800" data-sal-easing="ease-out-quart">
                We take care of everything — from extracting the information from you, doing additional research, defining the learning outcomes, choosing the right style, creating the content and choosing the best platform to put it on.
                </p>
                <p>You can be as involved or as hands-off as you want.</p>
                <div className='d-flex flex-sm-row flex-column justify-content-sm-start justify-content-centre align-items-center'
                    data-sal='slide-up' data-sal-duration='2000' data-sal-delay="800" data-sal-easing="ease-out-quart">
                    <Link className='btn btn-primary' to='/contact'>Talk with us</Link>
                    {/* MOBILE BUTTON*/}
                    <Link to={'/process'} className='btn btn-link --underline d-block d-sm-none'>Learn how it works</Link>
                    {/* DESKTOP BUTTON*/}
                    <Link to={'/process'} className='btn btn-outline-primary d-none d-sm-inline-block ml-3'>Learn how it works</Link>
                </div>
            </Col>

            <Col as='figure' lg={6} className='d-flex flex-column justify-content-center align-items-start align-items-sm-center mt-3 mt-sm-4 mt-lg-0'
                data-sal='slide-left' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart">
                <picture>
                    <source data-srcset='https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,e_saturation:8,h_560,q_auto/v1624490977/AdobeStock_302949812_pgs8iu.webp' type='image/webp'/>
                    <img data-src='https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,e_saturation:8,h_560,q_auto/v1624490977/AdobeStock_302949812_pgs8iu.png' className={'vpImage lazyload shadow-2'} loading="lazy" width='560' height='420' alt='Set of five lab diamonds' />
                </picture>
                <noscript>
                    <img alt='Podeum displaying three engagement rings' width='560' height='420' className={'rounded perfectRingImage shadow-2'} loading="lazy" src='https://res.cloudinary.com/dqpij5loy/image/upload/q_auto,c_scale,h_601/v1610610038/carousel-1_b7tuzf.jpg'/>
                </noscript>
            </Col>
        </Section>
    }

    __renderCourse30Days () {
        return <Section background='bg-peach' rowClasses={'d-flex flex-column-reverse flex-lg-row'}>

            <Col as='figure' lg={6} className='col-lg-6' data-sal='slide-left' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart">
                <picture>
                    <source data-srcset='https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,h_420,q_auto/v1624513961/AdobeStock_323315268_tyycvf.jpg' type='image/webp'/>
                    <img alt='Podeum displaying three engagement rings' width='560' height='420' className={'rounded lazyload perfectRingImage shadow-2'} loading="lazy" data-src='https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,h_560,q_auto/v1624513961/AdobeStock_323315268_tyycvf.jpg'/>
                </picture>
                <noscript>
                    <img src='https://res.cloudinary.com/dmcrkqndq/image/upload/c_scale,h_420,q_auto/v1624513961/AdobeStock_323315268_tyycvf.jpg' className={'vpImage shadow-2'} loading="lazy" width='560' height='420' alt='Set of five lab diamonds' />
                </noscript>
            </Col>

            <Col lg={6} className='col-lg-6'>
                <h2 className='h1' data-sal='slide-up' data-sal-duration='2000' data-sal-delay="500" data-sal-easing="ease-out-quart">Get your course created in 30 days</h2>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay="700" data-sal-easing="ease-out-quart">
                    {'You are the expert in what you do, and we are experts in creating online courses. Skip the learning curve and jump straight to selling and teaching your course in 30 days. '} 
                </p>
                <div data-sal='slide-up' data-sal-duration='2000' data-sal-delay="800" data-sal-easing="ease-out-quart">
                    {/* WRAPPER FOR ANIMATION */}
                    <Link className='btn btn-primary mb-0 mb-sm-4' to='/process'>Learn how it works</Link>  
                    {/* MOBILE BUTTON*/}
                    <Link to={'/cases'} className='btn btn-link --underline d-block d-sm-none my-3'>Read our case studies</Link>
                    {/* DESKTOP BUTTON*/}
                    <Link to={'/cases'} className='btn btn-outline-primary d-none d-sm-inline-block ml-3 my-3'>Read our case studies</Link>             
                </div>
            </Col>
        </Section>
    }

    __renderStructuredCourses () {
        return <Section background='bg-cream'>
            <Col lg={6} className='col-lg-6'>
                <h2 className='h1' data-sal='slide-up' data-sal-duration='2000' data-sal-delay="500" data-sal-easing="ease-out-quart">Structured, professional courses — the best course in your niche</h2>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay="800" data-sal-easing="ease-out-quart">Most online courses are poorly made. This is because when experts create courses they don't know how to structure them and have poorly defined outcomes. This is because experts know a lot of things about their niche implicitly — which makes teaching it difficult.</p>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay="800" data-sal-easing="ease-out-quart">We are experts in learning. We can extract the right information by asking the right questions and doing our own research to fill in any knowledge gaps. Our process will mean that your online course will be well structured, appropriately sized with the right type and style of content — building up from minimal knowledge up to well-defined learning outcomes.</p>
                <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay="800" data-sal-easing="ease-out-quart">Following our process means you will have one of  the best online courses in your niche. </p>
                <div data-sal='slide-up' data-sal-duration='2000' data-sal-delay="800" data-sal-easing="ease-out-quart">
                    {/* WRAPPER FOR ANIMATION */}
                    <Link className='btn btn-primary' to='/contact'>Talk with us</Link>
                    {/* MOBILE BUTTON*/}
                    <Link to={'/process'} className='btn btn-link --underline d-block d-sm-none'>Learn about our process</Link>
                    {/* DESKTOP BUTTON*/}
                    <Link to={'/process'} className='btn btn-outline-primary d-none d-sm-inline-block ml-3'>Learn how it works</Link>
                </div>
            </Col>
            <Col lg={6} as='figure' className='mt-sm-3 mt-3 mt-md-5 col-lg-6' data-sal='slide-left' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart">
                <picture>
                    <source data-srcset='https://res.cloudinary.com/quicksense/image/upload/v1566167017/Hero-iPhone_Illustrations_jvfg6n.webp' type='image/webp'/>
                    <img data-src='https://res.cloudinary.com/quicksense/image/upload/v1566167017/Hero-iPhone_Illustrations_jvfg6n.png' className={'sustainabilityImage lazyload'} loading="lazy"  width='540' height='660' alt='A sustainable lab diamond on top of a leaf' />
                </picture>
                <noscript>
                    <img src='https://res.cloudinary.com/quicksense/image/upload/v1566167017/Hero-iPhone_Illustrations_jvfg6n.png' className={'sustainabilityImage'} loading="lazy" width='540' height='660' alt='A sustainable lab diamond on top of a leaf' />
                </noscript>
            </Col>
        </Section>
    }

    __renderTestimonials () {
        return <Section spacing='py-3' background='bg-white'>
            <Col lg={12}>
            <h2 className='h1 text-center mt-0 mt-sm-4' data-sal='slide-up' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart">Testimonials</h2>
                <Row className='border rounded py-3 px-3 bg-white'>
                {
                    RP_TESTIMONIAL.map((data,index) => (
                    <Testimonials {...data} key={index} />
                    ))
                }
                </Row>
            </Col>
        </Section>
    }

    __renderCaseStudies () {
        return <Section animation={false} background='bg-peach'>
            <Col lg={12} className='col-lg-12'>
                <h2 className='h1 text-center mt-0 mt-sm-4' data-sal='slide-up' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart">Case Studies</h2>
                <Row>
                    {
                        HOW_IT_WORKS_DATA.map((data, index) => (
                            <Col lg={4} key={index} className='d-flex flex-column mt-0 mb-md-4 mb-lg-0 col-lg-4' data-sal='slide-right' data-sal-duration='2000' data-sal-delay={index==0?'300': index==1?'500': '700'} data-sal-easing="ease-out-quart">
                                <HowItWorksItem {...data} lastItem={HOW_IT_WORKS_DATA.length -1 == index ? true : false}/>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </Section>
    }

    __renderWhyLearningCurve () {
        return <Section animation={false} background={'bg-salmon'} spacing='py-4'>
            <Col lg={12}>
                <h2 className='h1 text-center mt-0 mt-sm-4' data-sal='slide-up' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart">Why pick Learning Curve?</h2>
                <Row>
                    {
                        WHY_LEARNING_CURVE.map((data, index) => (
                            <>
                            <Col lg={6} key={index} className='d-flex flex-column mt-5' data-sal='slide-right' data-sal-duration='2000' data-sal-delay={index==0?'300': index==1?'500': '700'} data-sal-easing="ease-out-quart">
                                <h3>{data.title}</h3>
                                <p>{data.description}</p>
                            </Col>
                            <hr className='d-block d-sm-none w-100'/>
                            </>
                        ))
                    }
                </Row>
            </Col>
        </Section>
    }

    __renderCustomerLogos (spacing, style) {
        return <Section background={'bg-peach'} spacing={spacing ? spacing : 'py-3'} containerClasses={style ? style : ''}>
            <Col lg={12}>
                <h2 className='h4 text-center' data-sal='slide-up' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart">Trusted by domain experts across the world</h2>
                <Row className='justify-content-center'>
                    {
                        CUSTOMER_LOGOS.map((row, index) => (
                            <Col lg={12} key={index}>
                                <Row className='justify-content-center my-0 '>
                                {
                                    row.map((logo, index) => (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={logo.link} 
                                            className={`${logo.space ? 'col-3 col-md-1 col-lg-1' : 'col-6 col-md-3 col-lg-2'} ${logo.p ? '' : 'd-none d-md-flex  my-sm-0'} my-4 d-flex justify-content-center align-items-center`}>
                                            <img src={logo.image} alt={logo.alt} key={index}/>
                                        </a>
                                    ))
                                }
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </Section>
    }

    render () {
        return (
            <>
                <SEO 
                    title='Home' 
                    description={'A 100% done-for-you course creation service. We\'ll extract your knowledge and create a course for you in 30 days.'}>
                </SEO>
                <Layout type='floating' classes='fw-landing-page text-center text-sm-left'>
                    {this.__renderHeroSection()}
                    {this.__renderCustomerLogos('pb-3', 'testimonials-hero')}
                    {this.__renderMonitizeKnowledge()}
                    {this.__renderCourse30Days()}
                    {this.__renderStructuredCourses()}
                    {this.__renderCaseStudies()}
                    {this.__renderWhyLearningCurve()}
                    {this.__renderTestimonials()}
                    {this.__renderCustomerLogos()}
                    <GetStarted 
                        background={'bg-salmon'} 
                        secondaryButton={true}
                        title={'Get started with Learning Curve'} 
                        description={'Whether you\'re still thinking about if you should create an online course or ready to pull the trigger, book at 30 minute discovery call or email us.'}/>
                </Layout>
            </>
        )
    }
}

export default IndexPage
