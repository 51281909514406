import React from "react";

const testimonialRpItem = ({image, name, testimonial, author, index}) => (
  <div className={`col-12 col-md-6 mt-md-0 ${index === 1 ? 'mt-3' : ''}`} key={index}>
    <img height={40} src={image} alt={name}/>
    <h2 className='h4 my-3'>{name} {index}</h2>
    <p >{testimonial}</p>
    <h3 className='h6 mt-3'>{author}</h3>
  </div>
)

export default testimonialRpItem