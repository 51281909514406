import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Button from 'react-bootstrap/Button'

class HowItWorksItem extends React.Component {
    render(){
        return <>
            {
                this.props.image && 
                <figure className={`rounded ${this.props.shadow && 'shadow'} mb-0 mt-0 mt-sm-0`} style={this.props.background && {backgroundColor: this.props.background}}>
                <picture>
                    <source data-srcset={this.props.imageWebp} type='image/webp' />
                    <img width={ this.props.imageWidth || 336} height={ this.props.imageHeight || 252} data-src={this.props.image} loading={'lazy'} className={`lazyload how-it-works__image ${this.props.imageClassName}`}  alt={this.props.imageCaption}/>
                </picture>
                <noscript>
                    <img width={ this.props.imageWidth || 336} height={ this.props.imageHeight || 252} loading={'lazy'} className={` how-it-works__image ${this.props.imageClassName}`} src={this.props.image} alt={this.props.imageCaption}/>
                </noscript>
                </figure>
            }
            <div className={`how-it-works__container ${this.props.cardBackground} ${this.props.shadow && 'shadow'} mb-5 mb-sm-0`}>
                <span className='d-block text-primary h4 font-weight-normal text-uppercase text-small mb-3'>{this.props.header}</span>
                <h2 className='mb-2 h3'>{this.props.title}</h2>
                <p className='mb-2' dangerouslySetInnerHTML={{__html: this.props.description}} />
                {
                    this.props.buttonText &&
                    <div className='mt-sm-auto w-100 d-flex align-items-stretch justify-content-center justify-content-sm-start'>
                        {
                            this.props.buttonType == 'internal' ?
                                <Link to={this.props.buttonAction} className='btn btn-outline-primary ml-0' style={{minWidth: 180}}>{this.props.buttonText}</Link>:
                                this.props.buttonType == 'book' ?
                                    <Link className='btn btn-outline-primary' to='/contact'>Talk with us</Link>:
                                    <Button href={this.props.buttonAction} variant='outline-primary' style={{minWidth: 180}}>{this.props.buttonText}</Button>
                        }
                    </div>
                }
            </div>
        </>
    }
}

HowItWorksItem.propTypes = {
    image: PropTypes.string,
    imageWebp: PropTypes.string,
    imageWidth: PropTypes.string,
    imageHeight: PropTypes.string,
    imageCaption: PropTypes.string,
    imageClassName: PropTypes.string,
    title: PropTypes.string,
    header: PropTypes.string,
    cardBackground: PropTypes.string,
    shadow: PropTypes.bool,
    description: PropTypes.string,
    buttonType: PropTypes.string,
    buttonAction: PropTypes.string,
    buttonText: PropTypes.string,
    background: PropTypes.string,
    lastItem: PropTypes.bool,
}


export default HowItWorksItem